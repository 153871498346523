function CloseButton(props) {
  return (
    <div onClick={props.onClick} className="cursor-pointer">
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0}
        aria-hidden="true"
        viewBox="0 0 512 512"
        className="size-7 transition-opacity hover:opacity-80"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z"
          stroke="none"
        />
      </svg>
    </div>
  );
}

export default CloseButton;
