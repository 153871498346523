function HamburgerMenu(props) {
  return (
    <div onClick={props.onClick} className="cursor-pointer">
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0}
        viewBox="0 0 20 20"
        aria-hidden="true"
        className="size-7 transition-opacity hover:opacity-80"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
          clipRule="evenodd"
          stroke="none"
        />
      </svg>
    </div>
  );
}

export default HamburgerMenu;
