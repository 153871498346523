import CloseButton from '@components/Icons/CloseButton';
import HamburgerMenu from '@components/Icons/HamburgerMenu';
import { useState } from 'react';

function MobileNavigation() {
  const [open, setOpen] = useState(false);

  const hambergericon = <HamburgerMenu onClick={() => setOpen(!open)} />;
  const closeicon = <CloseButton onClick={() => setOpen(!open)} />;

  return (
    <div>
      <div className="flex h-10 items-center justify-between">
        <a href="/">
          <img
            src="/favicon.svg"
            width="35"
            height="35"
            alt="Minimal Mockups SVG Logo"
            className="size-8"
          />
        </a>

        <div className="flex items-center space-x-4">
          <div>{open ? closeicon : hambergericon}</div>
        </div>
      </div>
      {open && (
        <nav>
          <ul className="my-8 space-y-5">
            <li className="text-base">
              <a href="/mockups/" className="hover:text-neutral-300">
                All Mockups
              </a>
            </li>
            <li className="text-base">
              <a href="/social/" className="hover:text-neutral-300">
                Social
              </a>
            </li>
            <li className="text-base">
              <a href="/license/" className="hover:text-neutral-300">
                License
              </a>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}

export default MobileNavigation;
